import { Grid, Typography, makeStyles } from "@material-ui/core";
import { IMapOrder } from "@shared/components/Map/models/models";
import { ICustomerDto } from "@shared/services/orders/dtos/orders/customerDto";
import { IPartnerDto } from "@shared/services/orders/dtos/orders/partnerDto";
import { IRoutePointDto } from "@shared/services/orders/dtos/orders/routePointDto";
import {
  getOrderCustomerDetailsAsync,
  getOrderPartnerDetailsAsync,
} from "@shared/services/orders/orders.service";
import { FC, memo, useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "50%",
  },
  image: {
    height: "10rem",
    width: "10rem",
    margin: "auto",
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    display: "block",
  },
}));

export interface RoutePointMarkerPopupProps {
  routePoint: IRoutePointDto;
  order: IMapOrder;
}

const RoutePointMarkerPopup: FC<RoutePointMarkerPopupProps> = ({ routePoint, order }) => {
  const classes = useStyles();

  const [customerInfoState, setCustomerInfoState] = useState<ICustomerDto>(null);
  const [partnerInfoState, setPartnerInfoState] = useState<IPartnerDto>(null);

  useEffect(() => {
    if (routePoint.isPickup === true) {
      loadPartnerData(routePoint.orderId);
    } else if (routePoint.isPickup === false) {
      loadCustomerData(routePoint.orderId);
    }
  }, [routePoint.isPickup, routePoint.orderId]);

  const loadPartnerData = (orderId: number) => {
    getOrderPartnerDetailsAsync(orderId).then((res) => {
      setPartnerInfoState(res);
    });
  };

  const loadCustomerData = (orderId: number) => {
    getOrderCustomerDetailsAsync(orderId).then((res) => {
      setCustomerInfoState(res);
    });
  };

  return (
    <>
      {order &&
      routePoint &&
      ((routePoint.isPickup && partnerInfoState) || (!routePoint.isPickup && customerInfoState)) ? (
        <Grid spacing={2} container>
          <Grid md={12} item>
            <Typography variant="body1" component="span">
              {routePoint.isPickup ? "Партнер: " : "Корисник: "}
            </Typography>
            <Typography variant="body1" component="span">
              {routePoint.isPickup
                ? order.partnerID + " " + partnerInfoState.name
                : order.customerNumber + " " + customerInfoState.name}
            </Typography>
          </Grid>
          <Grid md={12} item>
            <Typography variant="body1" component="span">
              Адреса:
            </Typography>
            <Typography variant="body1" component="span">
              {routePoint.isPickup ? partnerInfoState.address : customerInfoState.formattedAddress}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default memo(RoutePointMarkerPopup);

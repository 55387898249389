import RoutePointMarker from "./RoutePointMarker";
import { IMapOrder } from "@shared/components/Map/models/models";
import { IRoutePointDto } from "@shared/services/orders/dtos/orders/routePointDto";
import { FC, memo, useEffect } from "react";
import { useMap } from "react-leaflet";

interface RoutePointMarkersProps {
  routePoints: IRoutePointDto[];
  orders: IMapOrder[];
}

const RoutePointMarkers: FC<RoutePointMarkersProps> = ({ routePoints, orders }) => {
  const map = useMap();

  useEffect(() => {
    const handler = () => {
      const zoomLevel = map.getZoom();

      if (zoomLevel > 13) {
        map.getPane("tooltip-pane").style.visibility = "visible";
      } else {
        map.getPane("tooltip-pane").style.visibility = "hidden";
      }
    };

    map.on("zoomend", handler);
    return () => {
      map.off("zoomend", handler);
    };
  }, [map]);

  return (
    <>
      {routePoints
        ?.filter((routePoint) => !routePoint.onLocationAt)
        .map((routePoint) => (
          <RoutePointMarker
            key={`${routePoint.orderId}-${routePoint.id}-${routePoint.isPickup}`}
            routePoint={routePoint}
            order={orders.find((x) => x.id === routePoint.orderId)}
          />
        ))}
    </>
  );
};

export default memo(RoutePointMarkers);

import { WorkingStatus, WorkingStatusMacedonian } from "./types";
import employeeWeekToTable from "./utils/employeeWeekToTable";
import { Hour } from "@App/Scheduler/genericTypes";
import { workingStatusToEnglish } from "@App/Scheduler/utils/translateWorkingStatus";
import { isValidTime, isWorkingStatus } from "@App/Scheduler/utils/typeGuards";
import useStyles from "@App/Scheduler/utils/useStyles";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridCallbackDetails,
  GridCellParams,
  GridEventListener,
  MuiEvent,
} from "@mui/x-data-grid";
import {
  getScheduleForAllEmployeesPerPeriod,
  updateEmployeeSchedule,
} from "@shared/services/schedule.service";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { number } from "yup";

const EmployeeScheduleTable: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [employee, setEmployee] = useState<string>("");
  const [employeeId, setEmployeeId] = useState<string>("");
  const [weekDay, setWeekDay] = useState<string>("");
  const [startTime, setStartTime] = useState<Hour>("");
  const [endTime, setEndTime] = useState<Hour>("");
  const [workingStatus, setWorkingStatus] = useState<WorkingStatus>("True");
  const [scheduleData, setScheduleData] = useState(null);
  const [id, setId] = useState<number>(0);

  const [startDateFilter, setStartDateFilter] = useState<string>(
    dayjs().add(0, "day").format("YYYY-MM-DD")
  );
  const [endDateFilter, setEndDateFilter] = useState<string>(
    dayjs().add(14, "day").format("YYYY-MM-DD")
  );
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading } = useQuery(["schedule"], () =>
    getScheduleForAllEmployeesPerPeriod(startDateFilter, endDateFilter)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getScheduleForAllEmployeesPerPeriod(startDateFilter, endDateFilter);
        console.log(" data:", data);
        setScheduleData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [startDateFilter, endDateFilter]);
  function findIdByDateInList(dateToFind: string, list: any[]): number {
    const resource = list.find(obj => obj.date === dateToFind);
    return resource.id as number
  }
  const handleUpdateTime = (
    workingStatus: string,
    startTime: string,
    endTime: string,
    workingDay: string,
    id:Number
  ) => {
    console.log("updating schedule");
    console.log("working status:", workingStatus);
    console.log("start time:", startTime);
    console.log("end time:", endTime);
    console.log("working day:", workingDay);
    console.log("id record:", id);

    const request = {
      id: Number(id),
      employeeId: Number(employeeId),
      name: employee,
      surname: "",
      startTime: startTime,
      endTime: endTime,
      isWorking: workingStatus === "True",
      dateWork: weekDay,
      reason: "",
      workPerDay: true,
      employeePosition: 0,
    };

    console.log("Request Object:", request);

    updateEmployeeSchedule(request.employeeId.toString(), request)
      .then(() => {
        console.log("Schedule successfully updated.");
        enqueueSnackbar("Промените се успешно зачувани.", {
          variant: "success",
          autoHideDuration: 6000,
        });
        setModalOpen(false);
      })
      .catch((error) => {
        console.error("Error updating schedule:", error);
        enqueueSnackbar("Грешка при зачувување на промените.", {
          variant: "error",
          autoHideDuration: 6000,
        });
      });
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedStartDate = event.target.value;
    setStartDateFilter(selectedStartDate);

    const startDate = dayjs(selectedStartDate);
    let endDate = startDate.add(1, "week");
    const currentMonthEndDate = startDate.endOf("month");
    if (!endDate.isSame(startDate, "month")) {
      endDate = currentMonthEndDate;
    }
    setEndDateFilter(endDate.format("YYYY-MM-DD"));
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDateFilter(event.target.value);
  };
  console.log(scheduleData, "here");
  const classes = useStyles();
  const { columns, rows } = scheduleData
    ? employeeWeekToTable(scheduleData)
    : { columns: [], rows: [] };
  const handleEvent: GridEventListener<"cellDoubleClick"> = (
    params: GridCellParams<any>,
    event: MuiEvent<React.MouseEvent<HTMLElement>>,
    details: GridCallbackDetails
  ) => {
    const value = params.value as string;
    console.log(params, "value");
    console.log("GJORGJIIIIIIIIIIIIIIIII")
    if (isWorkingStatus(value) || isValidTime(value.split("|")[0].trim())) {
      setEmployee(params.id as string);
      setEmployeeId(params.row.employeeId as string);
      setId(findIdByDateInList(params.field, params.row.recordId))
      setWeekDay(params.field);
      if (isWorkingStatus(value)) {
        setWorkingStatus(workingStatusToEnglish(value as WorkingStatusMacedonian));
      }
      if (isValidTime(value.split("|")[0].trim())) {
        setStartTime(value.split("|")[0].trim() as Hour);
        setEndTime(value.split("|")[1].trim() as Hour);
        setWorkingStatus("True");
      }
      setModalOpen(true);
    }
  };
  if (isLoading) return <div>Loading...</div>;

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "20px",
          marginLeft: "10px",
          marginBottom: "5px",
        }}
      >
        <TextField
          label="Start Date"
          type="date"
          value={startDateFilter}
          onChange={handleStartDateChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDateFilter}
          onChange={handleEndDateChange}
          InputLabelProps={{ shrink: true }}
        />
      </div>

      <DataGrid
        onCellDoubleClick={handleEvent}
        rows={rows}
        getRowId={(row) => row.infoCol}
        columns={columns}
        columnHeaderHeight={0}
      />
      {
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <div className={classes.modalContent}>
            <h3>
              Промени ги работните часови за {employee} за ден {dayjs(weekDay).format("YYYY-MM-DD")}
            </h3>
            <Box mt={3}>
              <FormControl>
                <InputLabel shrink>Работен статус</InputLabel>
                <Select
                  label="Работен статус"
                  style={{ width: 150 }}
                  value={workingStatus}
                  onChange={(e) => {
                    setWorkingStatus(e.target.value as WorkingStatus);
                  }}
                >
                  <MenuItem value={"Sick"}>Болен</MenuItem>
                  <MenuItem value={"True"}>Работи</MenuItem>
                  <MenuItem value={"Free"}>Слободен</MenuItem>
                  <MenuItem value={"Vacation"}>Одмор</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box mt={3}>
              <Typography>Работно време:</Typography>
            </Box>

            <Box
              mt={2}
              mb={6}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="35%"
            >
              <TextField
                label="Почеток"
                type="time"
                inputProps={{ step: 300 }}
                value={startTime}
                onChange={(e) => {
                  setStartTime(e.target.value as Hour);
                }}
                size="small"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Крај"
                type="time"
                inputProps={{ step: 300 }}
                value={endTime}
                onChange={(e) => {
                  setEndTime(e.target.value as Hour);
                }}
                size="small"
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            <div className={classes.buttonDiv}>
              <Button
                variant="outlined"
                className={classes.errorOutlined}
                onClick={() => setModalOpen(false)}
              >
                Назад
              </Button>
              <Button
                variant="contained"
                className={classes.primaryContained}
                onClick={() => handleUpdateTime(workingStatus, startTime, endTime, weekDay, id)}
              >
                Зачувај
              </Button>
            </div>
          </div>
        </Modal>
      }
    </div>
  );
};

export default EmployeeScheduleTable;

